// src/components/Register.js
import React, { useState } from "react";
import { Form, Input, Button, Typography, Card, notification } from "antd";
import { auth } from "../../../firebase";
import { useNavigate } from "react-router-dom"; // Updated import
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";

const { Text } = Typography;
const DEBUG_MODE = process.env.REACT_APP_ENVIRONMENT === "prod" ? false : true;
const DISPOSABLE_EMAIL_DOMAINS = [
  "yopmail.com",
  "mailinator.com",
  "10minutemail.com",
  "guerrillamail.com",
  "temp-mail.org",
  "throwawaymail.com",
  "maildrop.cc",
  "trashmail.com",
  "dispostable.com",
  "getairmail.com",
  "fakeinbox.com",
  "emailondeck.com",
  "moakt.com",
  "mailcatch.com",
  "sharklasers.com",
  "spambog.com",
  "mail-temporaire.fr",
  "spamgourmet.com",
  "mytemp.email",
  "tempmailo.com",
  "mintemail.com",
  "inboxkitten.com",
  "33mail.com",
  "emailfake.com",
  "mailnesia.com",
];

const Register = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // Use useNavigate for redirection

  const onFinish = async (values) => {
    const { email, password } = values;

    //validate the email
    if (DISPOSABLE_EMAIL_DOMAINS.some((value) => email.includes(value))) {
      notification.error({
        message: "Disposable emails are not allowed to register",
      });
      return;
    }

    try {
      // Register the user with Firebase
      await createUserWithEmailAndPassword(auth, email, password);
      notification.success({
        message: "Registration Successful! Please login to continue.",
      });

      await sendEmailVerification(auth.currentUser);
      // Redirect to root
      navigate(process.env.REACT_APP_STARTING_PAGE || "/");
    } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        notification.error({
          message: "Email already in use.",
        });
        return;
      }
      console.error("Registration Error:", error.message);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f0f2f5",
      }}
    >
      <Card
        title="Register New Account"
        type="inner"
        style={{
          width: 400,
          borderRadius: 8,
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        {/* <div style={{ textAlign: 'center' }}>
          <Title level={2}>Register</Title>
        </div> */}
        <Text>Please enter your email and password to register.</Text>
        <Form name="register" onFinish={onFinish} style={{ marginTop: "15px" }}>
          <Form.Item
            name="email"
            rules={[{ required: true, message: "Please input your Email" }]}
          >
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              { required: true, message: "Please input your Password" },
              {
                min: 8, // Ensure at least 8 characters
                message: "Password must be at least 8 characters",
              },
              {
                pattern:
                  /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                message:
                  "Password must include at least one uppercase letter, one number, and one special character",
              },
            ]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            dependencies={["password"]}
            rules={[
              { required: true, message: "Please confirm your password" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Passwords do not match"));
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm Password" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} block>
              Register
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Register;
