export const qbTemplate = {
  title: "Quickbooks Compatible Check Paper",
  templateId: "qb", //never change this
  bankNickname: "",
  // templateImg: "qbtemplate.png",
  createdAt: new Date(),
  updatedAt: new Date(),
  enabled: true,
  isDeleted: false,
  bankAccount: {
    id: null,
  },
  imgSrc: null,
  options: {
    printSignature: false,
    printCheckNumber: false,
    printPayer: false,
    printLines: false,
    printBackground: false,
    backgroundUrl: null,
    printRoutingAndAccount: false,
  },
  coordinates: {
    bankNickname: [
      { x: 25, y: 465 },
      { x: 25, y: 717 },
    ],
    mailingInfo: {
      x: 58,
      y: 120,
    },
    writtenDollar: {
      y: 93,
      x: 22,
    },
    date: [
      {
        x: 490,
        y: 35,
      },
      {
        x: 48,
        y: 262,
      },
      {
        x: 48,
        y: 512,
      },
    ],
    payableTo: [
      {
        x: 70,
        y: 70,
      },
      {
        x: 135,
        y: 262,
      },
      {
        x: 135,
        y: 512,
      },
    ],
    memo: [
      {
        x: 48,
        y: 170,
      },
      {
        x: 230,
        y: 450,
      },
      {
        x: 230,
        y: 700,
      },
    ],
    amount: [
      {
        x: 475,
        y: 69,
      },
      {
        x: 500,
        y: 450,
      },
      {
        x: 500,
        y: 700,
      },
    ],
  },
};
