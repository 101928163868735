import React, { createContext, useState, useEffect, useContext } from "react";
import { auth, db } from "../firebase";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { notification, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import {
  doc,
  getDoc,
  collection,
  where,
  query,
  getDocs,
} from "firebase/firestore";
import { isTrialExpired } from "../utils/functions/checkTrialExpiration";
import LoadingSpinner from "../shared/Components/LoadingSpinner";

const AuthContext = createContext();
const IGNORED_ROUTES = [
  "/logout",
  "/terms-of-service",
  "/forgot-password",
  "/verify-check",
];

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [initialLoading, setInitialLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [invitation, setInvitation] = useState(false);
  const navigate = useNavigate();
  const [firebaseUserData, setFirebaseUserData] = useState(null);

  const fetchEnhancedUser = async (firebaseUser) => {
    try {
      const userRef = doc(db, "users", firebaseUser.uid);
      const userDoc = await getDoc(userRef);
      let enhancedUser = {
        uid: firebaseUser.uid,
        accessToken: firebaseUser.accessToken,
        email: firebaseUser.email,
        emailVerified: firebaseUser.emailVerified,
        ...(userDoc.exists() ? userDoc.data() : {}),
      };

      //if user does not have a clientID try to fetch by querying the clients for the user
      if (enhancedUser && !enhancedUser.clientId) {
        try {
          // Query the clients collection for a document where the userIds array contains firebaseUser.uid
          const clientsCollection = collection(db, "clients");
          const q = query(
            clientsCollection,
            where("userIds", "array-contains", firebaseUser.uid)
          );

          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            // If a match is found, retrieve the first matching document's ID
            const clientDoc = querySnapshot.docs[0]; // You could also handle multiple matches here if necessary
            enhancedUser.clientId = clientDoc.id;
          }
        } catch (error) {
          console.error("Error fetching client ID:", error);
          return null;
        }
      }

      //there's truly no client id so send them to onboarding
      if (enhancedUser && !enhancedUser.clientId) {
        // Process invitation onboarding or normal onboarding
        const invitesCollection = collection(db, "invites");
        const q = query(
          invitesCollection,
          where("email", "==", firebaseUser.email)
        );

        const inviteSnap = await getDocs(q);
        if (!inviteSnap.empty) {
          const data = inviteSnap.docs[0].data();
          setInvitation(data);
          navigate("/invitation-onboarding");
        } else {
          navigate("/onboarding");
        }
        setUser(firebaseUser);
      } else {
        const clientRef = doc(db, "clients", enhancedUser.clientId);
        const clientDoc = await getDoc(clientRef);

        if (clientDoc.exists()) {
          enhancedUser = {
            ...enhancedUser,
            clientData: clientDoc.data(),
          };
          // if (
          //   !enhancedUser?.clientData?.verifications?.sms ||
          //   !enhancedUser?.clientData?.verifications?.email ||
          //   !enhancedUser?.clientData?.verifications?.bank
          // ) {
          //   //onboard incomplete
          //   navigate("/onboarding");
          // }

          //prevent access if user is disabled
          if (enhancedUser?.clientData?.disabled) {
            notification.warning({
              message:
                "You account has been disabled. Please contact support at contact@simple-checks.com",
            });
            navigate("/logout");
          }
          if (enhancedUser?.clientData?.hasAccess) {
            if (enhancedUser?.clientData?.subscription === "trial") {
              const expired = await isTrialExpired(
                enhancedUser.clientId,
                enhancedUser.clientData.trialExpiresOn.toDate()
              );
              if (expired) {
                navigate("/subscription");
              }
            }

            if (
              user &&
              user?.clientData?.subscription?.productName === "Premium"
            )
              enhancedUser.isPremium = true;
            enhancedUser.isEnterprise = false;
            enhancedUser.isTrial = false;
            if (
              user &&
              user?.clientData?.subscription?.productName === "Enterprise"
            ) {
              enhancedUser.isEnterprise = true;
              enhancedUser.isPremium = true;
              enhancedUser.isTrial = false;
            } else {
              enhancedUser.isTrial = true;
              enhancedUser.isEnterprise = false;
              enhancedUser.isPremium = false;
            }

            setUser(enhancedUser);
          } else {
            navigate("/subscription");
          }
        } else {
          console.error("Client document not found");
        }
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const reloadUser = async () => {
    if (firebaseUserData) {
      try {
        // Reload the Firebase user's authentication data
        await firebaseUserData.reload();

        // Fetch enhanced user data again
        await fetchEnhancedUser(auth.currentUser);
        // notification.success({ message: "User data refreshed successfully" });
      } catch (error) {
        console.error("Error reloading user data:", error);
        notification.error({ message: "Failed to refresh user data" });
      }
    }
  };

  useEffect(() => {
    const currentPath = window.location.pathname;
    const isIgnoredRoute = IGNORED_ROUTES.some(
      (route) => currentPath === route || currentPath.startsWith(route)
    );

    if (isIgnoredRoute) {
      setInitialLoading(false);
      return;
    }

    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      setInitialLoading(true);
      setFirebaseUserData(firebaseUser);
      if (firebaseUser) {
        // Check if the token is older than 8 hours
        const tokenAge =
          Date.now() - new Date(firebaseUser.metadata.lastSignInTime).getTime();
        checkTokenAge();
        await fetchEnhancedUser(firebaseUser);
        setInitialLoading(false);
        return;
      } else {
        setUser(null);
        navigate("/logout");
      }
      setInitialLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const checkTokenAge = async () => {
    const tokenAge =
      Date.now() -
      new Date(firebaseUserData?.metadata?.lastSignInTime).getTime();
    const maxTokenAge = 8 * 60 * 60 * 1000; // 8 hours in milliseconds

    if (tokenAge > maxTokenAge) {
      await signOut(auth);
      navigate("/login");
      notification.warning({
        message: "Session expired, please login again",
      });
      setInitialLoading(false);
      return;
    }
  };
  const logout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Logout Error:", error);
    }
  };
  // Initialize Firestore

  const fetchClientId = async (firebaseUser) => {};

  if (initialLoading) {
    return <LoadingSpinner />;
  } else {
    return (
      <AuthContext.Provider
        value={{
          user,
          logout,
          setUser,
          loading,
          setLoading,
          invitation,
          firebaseUserData,
          reloadUser,
          checkTokenAge,
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  }
};

export const useAuth = () => {
  return useContext(AuthContext);
};
