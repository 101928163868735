import React, { useState, useEffect } from "react";
import {
  Row,
  Card,
  Col,
  Typography,
  Spin,
  Button,
  Checkbox,
  Input,
  Modal,
  Divider,
  message,
  notification,
  Upload,
  Tag,
} from "antd";
import {
  BankOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  LockOutlined,
  MailOutlined,
  LoginOutlined,
  UploadOutlined,
  FieldBinaryOutlined,
} from "@ant-design/icons";
import { useAuth } from "../../../contexts/AuthContext";
import { usePlaidLink } from "react-plaid-link";
import {
  DisplaySignature,
  SignaturePad,
} from "../../../shared/Components/Signature";
import axios from "axios";
import { functions, db, storage } from "../../../firebase";
import { httpsCallable } from "firebase/functions";
import {
  collection,
  doc,
  addDoc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { Link } from "react-router-dom";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { metaAccountVerificationUpsert } from "../../../services/metadata/accountVerification";
import { sendEmail } from "../../../utils/functions/mailer";

function BankingVerification({ nextStep, previousStep, onCancel }) {
  const { user, setUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [linkToken, setLinkToken] = useState(null);
  const [accountInfo, setAccountInfo] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [signatureModalOpen, setSignatureModalOpen] = useState(false);
  const url = process.env.REACT_APP_API_ENDPOINT;
  const [validated, setValidated] = useState(false);
  const [workflow, setWorkflow] = useState("");
  const [fileList, setFileList] = useState([]);
  const [fileUploadProgress, setFileUploadProgress] = useState(0);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [done, setDone] = useState(false);

  const VERIFICATION_TYPE = {
    PLAID: "plaid",
    UPLOAD: "upload",
    MICRODEPOSITS: "microdeposits",
  };
  const STATUS = {
    DISABLED: "disabled",
    VERIFIED: "verified",
    PRE_APPROVED: "pre-approved",
    PENDING_VERIFICATION: "pending verification",
  };
  // Fetch Link Token on Component Mount
  useEffect(() => {
    setLoading(true);
    const fetchLinkToken = async () => {
      try {
        const response = await axios.post(
          `${url}/v1/banking/create-link-token`,
          {
            userId: "unique-user-id", // Pass userId or other necessary data
          },
          {
            headers: {
              Authorization: `Bearer ${user.accessToken}`,
            },
          }
        );
        setLinkToken(response?.data?.link_token);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching link token:", error);
      }
    };
    fetchLinkToken(); // Auto-fetch link token when component mounts
  }, [url, user]);

  const updateAccountInfo = (updatedAccount) => {
    // Find the index of the account to be updated
    const updatedAccountIndex = accountInfo.findIndex(
      (account) => account?.account_id === updatedAccount?.account_id
    );

    if (updatedAccountIndex !== -1) {
      // Create a new array with the updated account
      const updatedAccountInfo = [
        ...accountInfo.slice(0, updatedAccountIndex),
        updatedAccount,
        ...accountInfo.slice(updatedAccountIndex + 1),
      ];

      // Update the accountInfo state with the new array
      setAccountInfo(updatedAccountInfo);
    }
  };

  const onSuccess = async (public_token) => {
    try {
      setLoading(true);
      // Send the public token to the backend to complete the process
      const response = await axios.post(
        `${url}/v1/banking/complete`,
        {
          public_token: public_token,
          // userId: "unique-user-id", // Pass userId or other necessary data
        },
        {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        }
      );
      // Handle the account information returned from backend
      setAccountInfo(...accountInfo, response?.data.account_info); // Assuming it's an array of accounts
    } catch (error) {
      console.error("Error during Plaid verification:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddAccount = async () => {
    console.log(selectedAccount);
    if (!selectedAccount?.accountNickname) {
      notification.error({
        message: "Give the account a nickname to show in the dropdowns",
      });
      return;
    }

    if (
      selectedAccount?.routing_number &&
      selectedAccount?.routing_number.trim().length !== 9
    ) {
      notification.error({ message: "Routing number must be 9 digits" });
      return;
    }

    if (
      selectedAccount?.routing_number &&
      selectedAccount?.account_number.trim().length < 8
    ) {
      notification.error({
        message: "US banks account number are at least 8 digits or more",
      });
      return;
    }
    setLoading(true);
    const verificationFile = selectedAccount?.verificationFile || "";
    delete selectedAccount?.verificationFile;
    if (workflow !== VERIFICATION_TYPE.PLAID) {
      selectedAccount.mask = selectedAccount?.account_number.slice(0, -4);
    }
    // Ensure selectedAccount.signature is explicitly true or false
    const updatedAccount = {
      ...selectedAccount,
      signature: !!selectedAccount?.signature, // Ensure it's a boolean
    };

    try {
      // Step 1: Call the cloud function to encrypt the data
      const encryptFunction = httpsCallable(functions, "encryptData");
      const {
        data: { encryptedData },
      } = await encryptFunction({
        plainText: JSON.stringify(updatedAccount),
      });

      // Step 2: Save the encrypted data to Firestore
      const userDocRef = doc(db, "clients", user.clientId);
      const accountsCollectionRef = collection(userDocRef, "accounts");
      let status = STATUS.VERIFIED;
      if (workflow === VERIFICATION_TYPE.UPLOAD) {
        status = STATUS.PRE_APPROVED;
      } else if (workflow === VERIFICATION_TYPE.MICRODEPOSITS) {
        status = STATUS.PENDING_VERIFICATION;
      }

      const accId = await addDoc(accountsCollectionRef, {
        encryptedAccount: encryptedData, // Save the encrypted data
        createdAt: serverTimestamp(), // Use Firestore server timestamp
        isDeleted: false,
        isDisabled: false,
        verificationFile: verificationFile,
        verificationType: workflow,
        ownerName: selectedAccount.ownerName,
        status,
        accountNickname: updatedAccount?.accountNickname,
      });

      setSelectedAccount({ ...selectedAccount, id: accId.id });
      message.success({ content: "Account added successfully" });

      // Optionally update the local state
      updateAccountInfo({ ...updatedAccount, added: true });
      //
      const clientRef = doc(db, "clients", user.clientId); // Reference to the client document
      await setDoc(
        clientRef,
        {
          verifications: {
            bank: true,
          },
        },
        { merge: true } // Use merge to update only verifications.sms
      );
      //update the user without refreshing
      setUser({
        ...user,
        clientData: {
          ...user.clientData,
          verifications: {
            ...user.clientData.verifications,
            bank: true,
          },
        },
      });

      //specific logic

      if (workflow === VERIFICATION_TYPE.UPLOAD) {
        try {
          metaAccountVerificationUpsert(user, {
            ...selectedAccount,
            verificationFile,
          });
          await sendEmail(
            user,
            process.env.REACT_APP_ADMIN_EMAIL,
            "New Verification Request",
            `client ${user.clientId} submitted a new account verification request via upload. ${JSON.stringify(selectedAccount)}`
          );
        } catch (e) {
          console.log(e.message);
        }
      }

      //continue setting flags
      setSelectedAccount(null);
      setSignatureModalOpen(false);
      setValidated(true);
      setDone(true);
      setFileList([]);
    } catch (error) {
      message.error({ content: "Failed to add account. Please try again." });
      console.error("Error in adding account:", error);
    } finally {
      setLoading(false);
    }
  };

  const { open, ready } = usePlaidLink({
    token: linkToken,
    onSuccess,
  });

  const handleFileUpload = async (file) => {
    setLoading(true);
    const fileName = `accountValidation_${uuidv4()}_${file.name}`;
    const storageRef = ref(storage, `${user.clientId}/${fileName}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setFileUploadProgress(progress);
      },
      (error) => {
        message.error({ content: "Upload failed. Please try again." });
        setLoading(false);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        message.success({ content: "File uploaded successfully." });
        setFileList([
          { name: file.name, url: downloadURL, cloudFileName: fileName },
        ]);

        setSelectedAccount({
          ...selectedAccount,
          verificationFile: fileName,
        });
        setUploadSuccess(true);
        setLoading(false);
      }
    );
  };

  const handleFileRemove = async (file) => {
    setLoading(true);
    try {
      // Ensure you're using the correct non-root reference by using the saved firebasePath
      if (!file.cloudFileName) {
        throw new Error("Invalid file path for deletion.");
      }
      // Recreate the reference to the file using its full path stored in firebasePath
      const fileRef = ref(storage, `${user.clientId}/${file.cloudFileName}`);

      // Delete the file from Firebase Storage
      await deleteObject(fileRef);

      // Update the file list state by removing the file
      setFileList([]);
      setSelectedAccount({ ...selectedAccount, verificationFile: null });
      message.success({ content: `File removed successfully.` });
    } catch (error) {
      message.error({ content: `Failed to remove file` });
    } finally {
      setLoading(false);
    }
  };

  // const handleEncrypt = async () => {
  //   try {
  //     const encryptFunction = httpsCallable(functions, "encryptData");
  //     const encrypted = await encryptFunction({
  //       plainText: JSON.stringify(selectedAccount),
  //     });

  //     setTest(encrypted.data.encryptedData);
  //   } catch (error) {
  //     console.error("Error encrypting data:", error);
  //   }
  // };

  // const handleDecrypt = async () => {
  //   console.log(test);
  //   const decryptFunction = httpsCallable(functions, "decryptData");
  //   const decrypted = await decryptFunction({
  //     encryptedText: test,
  //   });

  //   const rehydrated = JSON.parse(decrypted.data.plainText);
  //   setTest(rehydrated);
  // };

  return (
    <>
      <Row gutter={16}>
        <Col xs={12}>
          <Card style={{ height: "650", overflow: "auto" }}>
            <Row>
              <Col xs={24} style={{ textAlign: "center", marginTop: -10 }}>
                <BankOutlined className="info" style={{ fontSize: "2rem" }} />
                <Typography.Title level={5}>
                  Option 1 - Authenticate with Bank
                </Typography.Title>
                <Tag color="success">Immediate Validation</Tag>

                <Typography.Paragraph style={{ marginTop: 10 }}>
                  Authenticate with the bank you intend to print checks for.
                </Typography.Paragraph>
                <div>
                  {loading ? (
                    <Spin />
                  ) : (
                    <Button
                      type="primary"
                      onClick={() => {
                        setWorkflow(`plaid`);
                        open();
                      }}
                      disabled={!ready || loading}
                    >
                      <LoginOutlined /> Authenticate
                    </Button>
                  )}
                  {accountInfo.length > 0 ? (
                    <div>
                      <Typography.Title
                        className="text-gray"
                        level={5}
                        style={{ marginBottom: 20 }}
                      >
                        Linked Accounts:
                      </Typography.Title>
                      {accountInfo.map((account, index) => (
                        <>
                          <Row key={index} guter={16}>
                            <Col xs={18} style={{ textAlign: "left" }}>
                              <Typography.Text>
                                {(account.nickname || account.name) +
                                  " - " +
                                  account.mask}
                              </Typography.Text>
                            </Col>
                            <Col xs={6}>
                              {account.added ? (
                                <CheckCircleOutlined className="success" />
                              ) : (
                                <Button
                                  className="button-yellow"
                                  style={{ marginLeft: "-20px" }}
                                  onClick={() => setSelectedAccount(account)}
                                >
                                  <ExclamationCircleOutlined /> Review
                                </Button>
                              )}
                            </Col>
                            {/* <Button
                          style={{ marginLeft: "10px" }}
                          danger
                          onClick={() => handleDeleteAccount(account.accountId)}
                        >
                          Delete
                        </Button> */}
                          </Row>
                          <Divider style={{ marginTop: 5, marginBottom: 5 }} />
                        </>
                      ))}
                    </div>
                  ) : (
                    <>
                      <div
                        style={{
                          width: "100%",
                          paddingBottom: 20,
                          paddingTop: 10,
                          marginTop: 15,
                          border: "1px",
                        }}
                        className="background-lightGray"
                      >
                        <Typography.Title level={5} style={{ marginTop: 10 }}>
                          Option 2 - Upload
                        </Typography.Title>
                        <Tag color="success">Immediate Validation</Tag>
                        <Tag color="blue">Preferred</Tag>
                        <Typography.Paragraph style={{ marginTop: 10 }}>
                          Upload a picture of a void check OR bank document with
                          account name, routing and account number.
                        </Typography.Paragraph>
                        <Button
                          type="primary"
                          onClick={() => {
                            setAccountInfo([]);
                            setWorkflow(`upload`);
                          }}
                          disabled={!ready || loading}
                        >
                          <UploadOutlined /> Upload
                        </Button>
                      </div>
                      {/* <Typography.Title level={5} style={{ marginTop: 10 }}>
                        Option 3 - Microdeposits
                      </Typography.Title>
                      <Tag color="blue">Two Business Days</Tag>

                      <Typography.Paragraph style={{ marginTop: 10 }}>
                        We will deposit two small amounts to your account for
                        you to validate
                      </Typography.Paragraph>
                      <Tag color="default">Coming Soon</Tag>
                      <br /> */}
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </Card>
        </Col>

        {/* Card for showing selected account details */}
        <Col xs={12}>
          {/* PLAID WORKFLOW */}
          {selectedAccount && workflow === `plaid` && (
            <Card
              style={{ height: "650" }}
              title="Account Details"
              type="inner"
            >
              <Row>
                <Typography.Paragraph>
                  Institution Name: {selectedAccount?.institution_name}
                </Typography.Paragraph>
                <Typography.Paragraph>
                  Account Name: {selectedAccount?.name}
                </Typography.Paragraph>
                <Typography.Paragraph>
                  Routing Number: {selectedAccount?.routing_number}
                </Typography.Paragraph>
                <Typography.Paragraph>
                  Account Number: {selectedAccount?.account_number}
                </Typography.Paragraph>
                <Input
                  value={selectedAccount?.accountNickname}
                  placeholder="Account Nickname: BAC Checking - 1234"
                  style={{ marginBottom: 10 }}
                  required={true}
                  onChange={(e) => {
                    setSelectedAccount({
                      ...selectedAccount,
                      accountNickname: e.target.value,
                    });
                  }}
                />
                <Divider style={{ marginTop: 0, marginBottom: 10 }} />
                <Typography.Text style={{ marginBottom: 10 }}>
                  <strong> Info that prints on the top right of check</strong>
                </Typography.Text>
                <Input
                  value={selectedAccount?.ownerName}
                  placeholder="Business or Individual Name"
                  required={true}
                  onChange={(e) => {
                    setSelectedAccount({
                      ...selectedAccount,
                      ownerName: e.target.value,
                    });
                  }}
                />
                <Input
                  value={selectedAccount?.addressLine1}
                  placeholder="123 Main St #200 (Address Line 1)"
                  required={true}
                  onChange={(e) => {
                    setSelectedAccount({
                      ...selectedAccount,
                      addressLine1: e.target.value,
                    });
                  }}
                />
                <Input
                  value={selectedAccount?.addressLine2}
                  placeholder="Miami, Florida, 33076 (Address Line 2)"
                  style={{ marginBottom: 10 }}
                  required={true}
                  onChange={(e) => {
                    setSelectedAccount({
                      ...selectedAccount,
                      addressLine2: e.target.value,
                    });
                  }}
                />

                <Divider style={{ marginTop: 0, marginBottom: 10 }} />
                <Typography.Text style={{ marginBottom: 10 }}>
                  <strong> Signature Information</strong>
                </Typography.Text>

                <Checkbox
                  defaultChecked={false}
                  checked={selectedAccount.signature}
                  onChange={(e) =>
                    setSelectedAccount({
                      ...selectedAccount,
                      signature: e.target.checked,
                      signatureImg: null,
                    })
                  }
                >
                  I want to print check with signature
                </Checkbox>

                {selectedAccount?.signatureImg && selectedAccount?.signature ? (
                  <Row gutter={20}>
                    <Col xs={20}>
                      <DisplaySignature
                        encodedSignature={selectedAccount?.signatureImg}
                        height={50}
                      />
                    </Col>
                    <Col xs={24}>
                      <Link
                        onClick={() =>
                          setSelectedAccount({
                            ...selectedAccount,
                            signatureImg: null,
                          })
                        }
                      >
                        Clear
                      </Link>
                    </Col>
                  </Row>
                ) : (
                  <Button
                    onClick={() => setSignatureModalOpen(true)}
                    style={{ marginBottom: 10, marginTop: 10 }}
                    disabled={!selectedAccount?.signature}
                  >
                    Create Signature
                  </Button>
                )}

                <Modal
                  open={signatureModalOpen}
                  onClose={() => setSignatureModalOpen(false)}
                  footer={null}
                  maskClosable={false}
                  closeIcon={null}
                >
                  <SignaturePad
                    isOpen={signatureModalOpen}
                    selectedAccount={selectedAccount}
                    setSelectedAccount={setSelectedAccount}
                    onClose={() => setSignatureModalOpen(false)}
                    renderPad={signatureModalOpen}
                  />
                </Modal>

                <Button
                  block
                  style={{ marginTop: 10 }}
                  className="button-green"
                  onClick={handleAddAccount}
                  disabled={
                    loading ||
                    (selectedAccount.signature &&
                      !selectedAccount.signatureImg) ||
                    !selectedAccount.accountNickname ||
                    !selectedAccount.ownerName ||
                    !selectedAccount.addressLine1 ||
                    !selectedAccount.addressLine2
                  }
                >
                  Add to Simple-Checks
                </Button>
              </Row>
            </Card>
          )}
          {/* UPLOAD WORKFLOW */}

          {workflow === `upload` && (
            <Card
              style={{
                height: "650",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
            >
              <Row>
                <Input
                  value={selectedAccount?.institution_name || ""}
                  placeholder="Bank / Institution Name"
                  required={true}
                  onChange={(e) => {
                    setSelectedAccount({
                      ...selectedAccount,
                      institution_name: e.target.value,
                    });
                  }}
                />
                {/* <Typography.Paragraph>
                      Routing Number: {selectedAccount.routing_number}
                    </Typography.Paragraph> */}
                <Input
                  value={selectedAccount?.routing_number || ""}
                  placeholder="Routing Number"
                  required={true}
                  onChange={(e) => {
                    setSelectedAccount({
                      ...selectedAccount,
                      routing_number: e.target.value,
                    });
                  }}
                />
                {/* <Typography.Paragraph>
                      Account Number: {selectedAccount.account_number}
                    </Typography.Paragraph> */}
                <Input
                  value={selectedAccount?.account_number || ""}
                  placeholder="Account Number"
                  required={true}
                  onChange={(e) => {
                    setSelectedAccount({
                      ...selectedAccount,
                      account_number: e.target.value,
                    });
                  }}
                />
                <Input
                  value={selectedAccount?.accountNickname || ""}
                  placeholder="Account Nickname: BAC Checking - 1234"
                  required={true}
                  onChange={(e) => {
                    setSelectedAccount({
                      ...selectedAccount,
                      accountNickname: e.target.value,
                    });
                  }}
                />
                <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                <Typography.Text style={{ marginBottom: 10 }}>
                  <strong> Info that prints on the top right of check</strong>
                </Typography.Text>
                <Input
                  value={selectedAccount?.ownerName}
                  placeholder="Business or Individual Name"
                  required={true}
                  onChange={(e) => {
                    setSelectedAccount({
                      ...selectedAccount,
                      ownerName: e.target.value,
                    });
                  }}
                />
                <Input
                  value={selectedAccount?.addressLine1}
                  placeholder="123 Main St #200 (Address Line 1)"
                  required={true}
                  onChange={(e) => {
                    setSelectedAccount({
                      ...selectedAccount,
                      addressLine1: e.target.value,
                    });
                  }}
                />
                <Input
                  value={selectedAccount?.addressLine2}
                  placeholder="Miami, Florida, 33076 (Address Line 2)"
                  style={{ marginBottom: 10 }}
                  required={true}
                  onChange={(e) => {
                    setSelectedAccount({
                      ...selectedAccount,
                      addressLine2: e.target.value,
                    });
                  }}
                />
                <Divider style={{ marginTop: 10, marginBottom: 10 }} />

                <Typography.Text style={{ marginBottom: 10 }}>
                  <strong> Upload</strong>
                </Typography.Text>
                <Typography.Text style={{ marginBottom: 10 }}>
                  Upload a picture of a void check OR a bank document showing:
                  Account owner name, routing, account number
                </Typography.Text>
                <Upload
                  beforeUpload={(file) => {
                    handleFileUpload(file);
                    return false;
                  }}
                  fileList={fileList}
                  maxCount={1}
                  onRemove={(file) => {
                    handleFileRemove(file);
                    return false;
                  }}
                  disabled={loading}
                >
                  <Button icon={<UploadOutlined />} disabled={loading}>
                    Upload Document
                  </Button>
                </Upload>

                {fileUploadProgress > 0 && fileUploadProgress < 100 && (
                  <Typography.Text>
                    Upload Progress: {Math.round(fileUploadProgress)}%
                  </Typography.Text>
                )}

                <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                <Typography.Text style={{ marginBottom: 10 }}>
                  <strong> Signature Information</strong>
                </Typography.Text>

                <Checkbox
                  defaultChecked={false}
                  checked={selectedAccount?.signature}
                  onChange={(e) =>
                    setSelectedAccount({
                      ...selectedAccount,
                      signature: e.target.checked,
                      signatureImg: null,
                    })
                  }
                >
                  I want to print check with signature
                </Checkbox>

                {selectedAccount?.signatureImg && selectedAccount?.signature ? (
                  <Row gutter={20}>
                    <Col xs={20}>
                      <DisplaySignature
                        encodedSignature={selectedAccount?.signatureImg}
                        height={50}
                      />
                    </Col>
                    <Col xs={24}>
                      <Link
                        onClick={() =>
                          setSelectedAccount({
                            ...selectedAccount,
                            signatureImg: null,
                          })
                        }
                      >
                        Clear
                      </Link>
                    </Col>
                  </Row>
                ) : (
                  <Button
                    onClick={() => setSignatureModalOpen(true)}
                    style={{ marginBottom: 10, marginTop: 10 }}
                    disabled={!selectedAccount?.signature}
                  >
                    Create Signature
                  </Button>
                )}

                <Modal
                  open={signatureModalOpen}
                  onClose={() => setSignatureModalOpen(false)}
                  footer={null}
                  maskClosable={false}
                  closeIcon={null}
                >
                  <SignaturePad
                    isOpen={signatureModalOpen}
                    selectedAccount={selectedAccount}
                    setSelectedAccount={setSelectedAccount}
                    onClose={() => setSignatureModalOpen(false)}
                    renderPad={signatureModalOpen}
                  />
                </Modal>

                <Button
                  block
                  style={{ marginTop: 10 }}
                  className="button-green"
                  onClick={handleAddAccount}
                  disabled={
                    loading ||
                    (selectedAccount?.signature &&
                      !selectedAccount?.signatureImg) ||
                    !selectedAccount?.accountNickname ||
                    !selectedAccount?.ownerName ||
                    !selectedAccount?.addressLine1 ||
                    !selectedAccount?.addressLine2 ||
                    !selectedAccount?.institution_name ||
                    !selectedAccount?.routing_number ||
                    !selectedAccount?.account_number ||
                    !selectedAccount?.verificationFile
                  }
                >
                  Add to Simple-Checks
                </Button>
              </Row>
            </Card>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={18} style={{ marginTop: 30 }}>
          <Typography.Paragraph className="success">
            <LockOutlined /> This information is secure and encrypted
          </Typography.Paragraph>
        </Col>

        <Col xs={3} style={{ textAlign: "right", marginTop: 30 }}>
          {workflow && accountInfo && (
            <Button
              type="default"
              onClick={() => {
                setAccountInfo([]);
                setWorkflow(null);
                setSelectedAccount(null);
                setDone(false);
              }}
            >
              Start Over
            </Button>
          )}
        </Col>
        <Col xs={3} style={{ textAlign: "right", marginTop: 30 }}>
          {done && (
            <Button
              type="primary"
              onClick={() => {
                setAccountInfo([]);
                setWorkflow(null);
                setSelectedAccount(null);
                setDone(false);
                onCancel();
              }}
            >
              Close
            </Button>
          )}
        </Col>

        {/* <Col xs={3} style={{ textAlign: "right", marginTop: 30 }}>
          <Button
            type="primary"
            disabled={loading || !validated}
            onClick={nextStep}
          >
            Close
          </Button>
        </Col> */}
      </Row>
    </>
  );
}

export default BankingVerification;
