import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Card, Button } from "antd";
import "../../../shared/styles/stripeCheckout.css";

const StripeCheckoutForm = ({ plan, user, onSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [hasDefaultPaymentMethod, setHasDefaultPaymentMethod] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const url = process.env.REACT_APP_API_ENDPOINT;

  useEffect(() => {
    const checkDefaultPaymentMethod = async () => {
      if (user.clientData.stripeCustomerId && url) {
        try {
          const response = await fetch(
            `${url}/v1/payments/stripe/stripeCustomerInfo?stripeCustomerId=${user.clientData.stripeCustomerId}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${user.accessToken}`,
              },
            }
          );

          const data = await response.json();

          if (response.ok) {
            setHasDefaultPaymentMethod(data.hasDefaultPaymentMethod);
          } else {
            setErrorMessage(data.error);
          }
        } catch (error) {
          setErrorMessage("Failed to check payment method.");
        }
      }
    };

    checkDefaultPaymentMethod();
  }, [user, url]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("checkout form");
    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    try {
      let paymentMethodId = null;

      if (!hasDefaultPaymentMethod) {
        const paymentMethodResult = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardElement),
          billing_details: {
            name: user.name,
            email: user.email,
          },
        });

        if (paymentMethodResult.error) {
          setErrorMessage(paymentMethodResult.error.message);
          setIsProcessing(false);
          return;
        }

        paymentMethodId = paymentMethodResult.paymentMethod.id;
      }

      const response = await fetch(`${url}/v1/payments/stripe/subscribe`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.accessToken}`,
        },
        body: JSON.stringify({
          stripeProductId: plan.stripeProductId,
          paymentMethodId: paymentMethodId || null,
          stripeCustomerId: user.clientData.stripeCustomerId || null,
          stripeSubscriptionId:
            user.clientData.subscription.stripeSubscriptionId ?? null,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        window.location.reload();
      } else {
        setErrorMessage(data.error);
      }
    } catch (error) {
      setErrorMessage("Something went wrong. Please try again.");
    }

    setIsProcessing(false);
  };

  const cardElementOptions = {
    style: {
      base: {
        fontSize: "16px",
        color: "#32325d",
        fontFamily: "'Helvetica Neue', Helvetica, sans-serif",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
    hidePostalCode: true, // Optional: Hide postal code if unnecessary
  };

  return (
    <Card>
      <form onSubmit={handleSubmit} style={{ marginTop: "50px" }}>
        {!hasDefaultPaymentMethod && (
          <div style={{ marginBottom: "20px" }}>
            <label>Enter your card details</label>
            <CardElement options={cardElementOptions} />
          </div>
        )}
        {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
        <Button
          block
          disabled={isProcessing || !stripe || !elements}
          type="primary"
          onClick={handleSubmit}
          style={{ marginTop: "50px" }}
        >
          {isProcessing ? "Processing..." : "Confirm Change"}
        </Button>
      </form>
    </Card>
  );
};

export default StripeCheckoutForm;
