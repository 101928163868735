import { useState, useEffect } from "react";
import {
  Card,
  Table,
  Button,
  Modal,
  DatePicker,
  message,
  Input,
  Row,
  Col,
  Menu,
  Dropdown,
  Typography,
} from "antd"; // Import Input for search
import { doc, updateDoc, collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase"; // Assuming you have initialized Firebase in this file
import { useAuth } from "../../contexts/AuthContext";
import {
  CheckCircleOutlined,
  MobileOutlined,
  MailOutlined,
  BankOutlined,
  DownOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { formatDate } from "../../utils/functions/dates";

const { Search } = Input;

function Clients() {
  const { user } = useAuth();
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]); // To handle filtered data
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingClient, setEditingClient] = useState(null);
  const [newTrialDate, setNewTrialDate] = useState(null);

  // Fetch clients data from Firestore
  const fetchClients = async () => {
    setLoading(true);
    try {
      const querySnapshot = await getDocs(collection(db, "clients"));
      const clientsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setClients(clientsData);
      setFilteredClients(clientsData); // Initialize filtered clients
    } catch (error) {
      message.error("Failed to fetch clients.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user && user.role === "employee") {
      fetchClients();
    }
  }, [user]);

  // Search by Client Name or Admin Email
  const handleSearch = (value) => {
    const filteredData = clients.filter((client) => {
      const adminEmail =
        client.users && client.users[0] ? client.users[0].email : "";
      return (
        client?.clientName?.toLowerCase().includes(value.toLowerCase()) ||
        adminEmail.toLowerCase().includes(value.toLowerCase())
      );
    });
    setFilteredClients(filteredData); // Update filtered clients
  };

  // Handle table changes (filters, sorting)
  const handleTableChange = (pagination, filters, sorter) => {
    let filteredData = [...clients];

    // Apply filters for "Has Access"
    if (filters.disabled) {
      filteredData = filteredData.filter(
        (client) => filters.disabled.includes(client.disabled)
      );
    }

    // Apply filters for "Subscription"
    if (filters.subscription) {
      filteredData = filteredData.filter((client) => {
        const subscriptionValue =
          client.subscription?.productName || client.subscription || "N/A";
        return filters.subscription.includes(subscriptionValue);
      });
    }

    // Apply filters for "Verifications"
    if (filters.verifications) {
      filteredData = filteredData.filter((client) => {
        const { sms, email, bank } = client.verifications || {};
        const verifications = { sms, email, bank };
        return filters.verifications.some(
          (filter) => verifications[filter]
        );
      });
    }

    setFilteredClients(filteredData);
  };

  // Update client access (enable/disable)
  const toggleAccess = async (id, currentAccess) => {
    try {
      const clientRef = doc(db, "clients", id);
      await updateDoc(clientRef, { disabled: !currentAccess });

      const updatedClients = clients.map((client) =>
        client.id === id ? { ...client, disabled: !currentAccess } : client
      );

      // Update both clients and filteredClients to reflect the change
      setClients(updatedClients);
      setFilteredClients(updatedClients);

      message.success("Access updated successfully.");
    } catch (error) {
      message.error("Failed to update access.");
      console.error(error);
    }
  };

  // Handle modal to update trial expiration date
  const showModal = (client) => {
    setEditingClient(client);
    setNewTrialDate(moment(client.trialExpiresOn));
    setIsModalOpen(true);
  };

  const handleDateChange = (date) => {
    setNewTrialDate(date);
  };

  const handleOk = async () => {
    if (!newTrialDate || !editingClient) return;
    try {
      const clientRef = doc(db, "clients", editingClient.id);
      await updateDoc(clientRef, {
        trialExpiresOn: newTrialDate.toDate(),
      });
      const updatedClients = clients.map((client) =>
        client.id === editingClient.id
          ? { ...client, trialExpiresOn: newTrialDate.toDate() }
          : client
      );
      setClients(updatedClients);
      setFilteredClients(updatedClients);
      message.success("Trial expiration date updated.");
      fetchClients();
    } catch (error) {
      message.error("Failed to update trial expiration date.");
      console.error(error);
    } finally {
      setIsModalOpen(false);
      setEditingClient(null);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // Verification column rendering logic
  const renderVerificationIcons = (record) => {
    const { sms, email, bank } = record.verifications;
    const allVerified = sms && email && bank;

    if (allVerified) {
      return <CheckCircleOutlined className="success" />;
    }

    return (
      <>
        {sms ? (
          <MobileOutlined className="success" style={{ paddingRight: 10 }} />
        ) : (
          <MobileOutlined className="error" style={{ paddingRight: 10 }} />
        )}
        {email ? (
          <MailOutlined className="success" />
        ) : (
          <MailOutlined className="error" />
        )}
        {bank ? (
          <BankOutlined className="success" style={{ paddingLeft: 10 }} />
        ) : (
          <BankOutlined className="error" style={{ paddingLeft: 10 }} />
        )}
      </>
    );
  };

  // Table columns definition
  const columns = [
    {
      title: "Client Name",
      dataIndex: "clientName",
      key: "clientName",
    },

    {
      title: "Admin Email",
      key: "adminEmail",
      render: (text, record) =>
        record.users && record.users[0] ? record.users[0].email : "N/A",
    },

    {
      title: "Disabled",
      dataIndex: "disabled",
      key: "disabled",
      filters: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
      onFilter: (value, record) => record.disabled === value,
      render: (text, record) => (
        <span style={{ color: record?.disabled ? "red" : "" }}>
          {record.disabled ? "Yes" : "No"}
        </span>
      ),
    },
    {
      title: "Date Added",
      dataIndex: "dateAdded",
      key: "dateAdded",
      sorter: (a, b) => new Date(a.dateAdded) - new Date(b.dateAdded),
      render: (date) => formatDate(date),
    },
    {
      title: "Subscription",
      dataIndex: "subscription",
      key: "subscription",
      filters: [
        ...Array.from(
          new Set(
            clients.map(
              (client) =>
                client.subscription?.productName || client.subscription || "N/A"
            )
          )
        ).map((value) => ({ text: value, value })),
      ],
      onFilter: (value, record) => {
        const subscriptionValue =
          record.subscription?.productName || record.subscription || "N/A";
        return subscriptionValue === value;
      },
      render: (text, record) => {
        // Check if 'subscription' exists and fallback to 'subscription.productName'
        return record.subscription?.productName
          ? record.subscription?.productName
          : record.subscription || "N/A";
      },
    },
    {
      title: "Trial Ends",
      dataIndex: "trialExpiresOn",
      key: "trialExpiresOn",
      sorter: (a, b) => new Date(a.trialExpiresOn) - new Date(b.trialExpiresOn),
      render: (trialExpiresOn) => formatDate(trialExpiresOn),
    },
    {
      title: "Verifications",
      key: "verifications",
      render: (text, record) => renderVerificationIcons(record),
      sorter: (a, b) => {
        const aCount = [
          a.verifications.sms,
          a.verifications.email,
          a.verifications.bank,
        ].filter(Boolean).length;
        const bCount = [
          b.verifications.sms,
          b.verifications.email,
          b.verifications.bank,
        ].filter(Boolean).length;
        return aCount - bCount;
      },
      filters: [
        { text: "SMS Verified", value: "sms" },
        { text: "Email Verified", value: "email" },
        { text: "Bank Verified", value: "bank" },
      ],
      onFilter: (value, record) => {
        const { sms, email, bank } = record.verifications || {};
        const verifications = { sms, email, bank };
        return verifications[value];
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => {
        const menu = (
          <Menu>
            <Menu.Item
              key="1"
              disabled={!user || user.role !== "employee"}
              onClick={() => toggleAccess(record.id, record?.disabled || false)}
            >
              {record.disabled ? "Enable Access" : "Disable Access"}
            </Menu.Item>
            <Menu.Item key="2" onClick={() => showModal(record)}>
              Change Trial
            </Menu.Item>
          </Menu>
        );

        return (
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button>
              Actions <DownOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <h1 className="monospace">Clients</h1>
      {/* Record count and search input */}
      <Card style={{ width: "fit-content", minWidth: "100%" }}>
        <Row>
          <Col span={18}>
            <Typography.Paragraph>
              Record Count: {filteredClients.length}
            </Typography.Paragraph>
          </Col>
          <Col span={6}>
            <Search
              placeholder="Search by Client Name or Admin Email"
              onSearch={handleSearch} // Trigger search on input
              enterButton
              style={{ marginBottom: 20 }}
            />
          </Col>
        </Row>
        <Table
          dataSource={filteredClients} // Use filtered clients for the table
          columns={columns}
          rowKey="id"
          loading={loading}
          pagination={false}
          onChange={handleTableChange} // Handle table changes like filtering/sorting
        />
      </Card>

      {/* Modal for updating trial expiration date */}
      <Modal
        title="Change Trial Expiration Date"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {editingClient && editingClient.trialExpiresOn && (
          <DatePicker
            style={{ width: "50%", marginTop: "50px", marginBottom: "50px" }}
            onChange={handleDateChange} // Update the new date in state
          />
        )}
      </Modal>
    </>
  );
}

export default Clients;
