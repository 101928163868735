import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { db } from "../../../firebase.js";
import { doc, getDoc } from "firebase/firestore";
import { Spin, Layout, Card, Typography, Divider } from "antd";
import { LockOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

export default function ValidateCheck() {
  const { checkInfo } = useParams();

  const [clientId, checkId] = checkInfo.split(":");
  const [checkData, setCheckData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCheckData = async () => {
      try {
        // Construct the document reference
        const docRef = doc(db, "metaChecks", checkId);

        // Fetch the document
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          // Check if the document matches the clientId
          const data = docSnap.data();
          if (data.clientId === clientId) {
            setCheckData(data);
          } else {
            setError("Client ID does not match.");
          }
        } else {
          setError("No such document!");
        }
      } catch (e) {
        setError("Error fetching document: " + e.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCheckData();
  }, [clientId, checkId]);
  const { Content } = Layout;

  if (loading) {
    return (
      <div style={{ textAlign: "center", marginTop: "30vh" }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <Layout style={{ minHeight: "100vh", backgroundColor: "#fff" }}>
        <Content
          style={{
            display: "flex",
            justifyContent: "center",
            // alignItems: "center",
            padding: "50px 0",
          }}
        >
          <div style={{ textAlign: "center" }}>
            {/* Logo Image */}
            <img
              src="/images/logo/logo.png"
              alt="Logo"
              style={{ width: "150px", marginBottom: "20px" }}
            />

            {/* Card Component */}
            <Card
              type="inner"
              title="Check Verification"
              style={{
                width: 400,
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              {" "}
              {checkData ? (
                <>
                  <Typography.Title level={2}>
                    <span className="success">
                      <LockOutlined /> {"  "}
                      Authentic Check
                    </span>
                  </Typography.Title>
                  <p>
                    This check issuer has been verified and the check has been
                    printed with simple-checks.com
                  </p>
                  <p>
                    Proceed endorsing and depositing according to the
                    instructions provided with the check
                  </p>
                  <Divider />
                  <p>
                    <strong>Check Number:</strong> {checkData.checkNumber}
                  </p>
                  <p>
                    <strong>Payable To:</strong> {checkData.payableTo}
                  </p>
                  <p>
                    <strong>Amount:</strong> {"$" + checkData.amount}
                  </p>
                  <Divider />

                  <Typography.Paragraph>
                    Be aware of the latest check fraud trends by checking our{" "}
                    <a href="https://simple-checks.com/fraud">fraud info</a>{" "}
                    page
                  </Typography.Paragraph>
                  <Typography.Title level={5}>
                    Printing your own checks too!{" "}
                    <a href="https://simple-checks.com">click here</a>
                  </Typography.Title>
                </>
              ) : (
                <>
                  <h2 className="danger">
                    <span className="warning">
                      <ExclamationCircleOutlined /> CHECK NOT FOUND
                    </span>
                  </h2>
                  <Typography.Paragraph>
                    No records of this check has been found. If you believe this
                    is a mistake, please click{" "}
                    <a href="https://simple-checks.com/contact">here </a>
                    to contact us and report
                  </Typography.Paragraph>
                  <Typography.Paragraph>
                    Be aware of the latest check fraud trends by checking our{" "}
                    <a href="https://simple-checks.com/fraud">fraud info</a>{" "}
                    page
                  </Typography.Paragraph>
                </>
              )}
            </Card>
          </div>
        </Content>
      </Layout>
      {/* <h1>Validate Check</h1>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {checkData ? (
        <div>
          <p>
            <strong>Payable To:</strong> {checkData.checkNumber}
          </p>
          <p>
            <strong>Payable To:</strong> {checkData.payableTo}
          </p>
          <p>
            <strong>Amount:</strong> {"$" + checkData.amount}
          </p>
          {/* Render other fields as necessary */}
      {/* </div> */}
      {/* ) : ( */}
      {/* !error && <Spin /> */}
      {/* )} */}
    </>
  );
}
