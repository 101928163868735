import React, { useRef, useState, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Row, Col, Button, Image } from "antd";

export function SignaturePad({
  selectedAccount,
  setSelectedAccount,
  onClose,
  renderPad,
  isOpen,
}) {
  const sigCanvas = useRef(null);
  const [loading, setLoading] = useState(false);

  const saveSignature = () => {
    setLoading(true);
    try {
      const signatureDataURL = sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      const updatedAccount = {
        ...selectedAccount,
        signatureImg: signatureDataURL,
      };
      setSelectedAccount(updatedAccount);
      onClose();
    } catch (error) {
      console.error("Error saving signature:", error);
    } finally {
      setLoading(false);
    }
  };

  // Trigger canvas re-render manually on modal open
  useEffect(() => {
    if (isOpen) {
      const resizeCanvas = () => {
        if (sigCanvas.current) {
          // Clear and reinitialize the canvas
          sigCanvas.current.clear();
          sigCanvas.current.off(); // Reset any event listeners
          sigCanvas.current.on(); // Re-attach event listeners
        }
        // Manually trigger a resize event to force reflow
        window.dispatchEvent(new Event("resize"));
      };

      // Delay to ensure modal is fully rendered before resizing canvas

      const timeoutId = setTimeout(resizeCanvas, 700);

      return () => clearTimeout(timeoutId);
    }
  }, [isOpen]);

  return (
    <>
      <Row>
        <div
          style={{
            width: "100%",
            height: "130px",
            position: "relative",
            border: "1px solid gray",
            borderRadius: 5,
          }}
        >
          {renderPad && (
            <SignatureCanvas
              ref={sigCanvas}
              penColor="black"
              minWidth={0.5} // Fine-tune the stroke width
              maxWidth={1.75}
              throttle={10}
              canvasProps={{
                style: {
                  width: "100%",
                  height: "100%",
                },
              }}
            />
          )}
        </div>
      </Row>
      <Row style={{ marginTop: 15 }}>
        <Col span={12}>
          <Button className="button-yellow" onClick={onClose}>
            Cancel
          </Button>
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <Button onClick={() => sigCanvas.current.clear()}>Clear</Button>
          <Button
            className="button-green"
            onClick={saveSignature}
            disabled={loading}
            style={{ marginLeft: 15 }}
          >
            {loading ? "Saving..." : "Save Signature"}
          </Button>
        </Col>
      </Row>
    </>
  );
}

export function DisplaySignature({ encodedSignature, height, width }) {
  return (
    <Row>
      {encodedSignature ? (
        <Image
          style={{ height, width }}
          src={encodedSignature}
          alt="User Signature"
        />
      ) : (
        <p>No signature available</p>
      )}
    </Row>
  );
}
