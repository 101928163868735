import { functions } from "../../firebase";
import { httpsCallable } from "firebase/functions";

export function generateSecretKey(length = 64) {
  const array = new Uint8Array(length);
  window.crypto.getRandomValues(array);
  return btoa(String.fromCharCode(...array));
}

export async function decryptAccounts(accounts) {
  if (accounts && accounts.length > 0) {
    try {
      const decryptedValues = await Promise.all(
        accounts.map(async (account) => {
          const decryptFunction = httpsCallable(functions, "decryptData");
          const decryptedAccountInfo = await decryptFunction({
            encryptedText: account.encryptedAccount,
          });
          const { encryptedAccount, ...restOfAccount } = account;
          return {
            ...restOfAccount,
            account_info: JSON.parse(decryptedAccountInfo.data.plainText),
          };
        })
      );
      return decryptedValues;
    } catch (e) {
      console.error(e.message);
    }
  }
}
