import { Menu, Badge } from 'antd';
import { UserOutlined, EditOutlined, SettingOutlined, ShoppingCartOutlined, QuestionCircleOutlined, LogoutOutlined, ImportOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from "../contexts/AuthContext";

export default function MainMenu() {
  const { logout, user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const url = process.env.REACT_APP_SHOP_URL;

  const items = [
    {
      key: "/payees",
      icon: <UserOutlined />,
      label: "Payees",
      onClick: () => {
        navigate("/payees");
      },
    },
    {
      key: "/checks",
      icon: <EditOutlined />,
      label: "Checks",
      onClick: () => {
        navigate("/checks");
      },
    },
    {
      key: "/import-checks",
      icon: <ImportOutlined />,
      label: (
        <span style={{ position: 'relative', display: 'inline-block' }}>
          Import Checks
          <Badge
  count="New"
  color="#ff4757"
  style={{
    position: 'absolute',
    top: '-15px',    
    right: '-30px',  
    fontSize: '10px', 
    height: '15px',   
    lineHeight: '15px', 
    paddingLeft: "5px",
    paddingRight: "5px",

  }}
/>
        </span>
      ),
      onClick: () => {
        navigate("/import-checks");
      },
    },
    {
      key: "/shop",
      icon: <ShoppingCartOutlined />,
      label: "Buy Supplies",
      onClick: () => {
        window.location.href = url;
      },
    },
    {
      key: "/help",
      icon: <QuestionCircleOutlined />,
      label: "Help",
      onClick: () => {
        navigate("/help");
      },
    },
    {
      key: "/settings",
      icon: <SettingOutlined />,
      label: "Settings",
      onClick: () => {
        navigate("/settings");
      },
    },
    {
      key: "/logout",
      icon: <LogoutOutlined />,
      label: "Logout",
      onClick: () => {
        logout();
      },
    },
  ];

  const itemsEmployees = [
    {
      key: "/clients",
      icon: <UserOutlined />,
      label: "Clients",
      onClick: () => {
        navigate("/clients");
      },
    },
  ];

  if (user && user?.role === "employee") {
    items.push(
      {
        key: "divider-1", // Unique key for the divider
        type: "divider", // Indicate this as a divider
      },
      ...itemsEmployees
    );
  }

  return (
    <Menu
      mode="inline"
      style={{
        backgroundColor: "#dfe6e9",
        height: "100vh",
        paddingTop: "15px",
      }}
      selectedKeys={[location.pathname]}
      defaultSelectedKeys={["/payees"]}
      items={items.map(item => {
        if (item.type === "divider") {
          return { type: 'divider', key: item.key }; // Return divider object
        }
        return {
          key: item.key,
          icon: item.icon,
          label: item.label,
          onClick: item.onClick,
        };
      })}
    />
  );
}
