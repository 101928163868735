export function convertAmountToWords(amount) {
  // Function to convert a number into words
  if (!amount) return;

  const numberToWords = (num) => {
    const singleDigits = [
      "",
      "one",
      "two",
      "three",
      "four",
      "five",
      "six",
      "seven",
      "eight",
      "nine",
    ];
    const twoDigits = [
      "",
      "ten",
      "eleven",
      "twelve",
      "thirteen",
      "fourteen",
      "fifteen",
      "sixteen",
      "seventeen",
      "eighteen",
      "nineteen",
    ];
    const tensMultiple = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];
    const tensPower = ["", "thousand", "million", "billion"];

    if (num === "0") return "zero";
    if (num.length > 9) return "overflow";

    let numArray = ("000000000" + num).substr(-9).match(/.{1,3}/g);
    let words = "";

    for (let i = 0; i < numArray.length; i++) {
      let n = parseInt(numArray[i]);

      if (n !== 0) {
        let str = "";

        if (Math.floor(n / 100) !== 0) {
          str += singleDigits[Math.floor(n / 100)] + " hundred ";
        }

        n %= 100;

        if (n > 0 && n < 10) {
          str += singleDigits[n];
        } else if (n >= 10 && n < 20) {
          str += twoDigits[n - 10];
        } else if (n >= 20) {
          str += tensMultiple[Math.floor(n / 10)];
          if (n % 10 !== 0) {
            str += " " + singleDigits[n % 10];
          }
        }

        words += str + " " + tensPower[numArray.length - i - 1] + " ";
      }
    }

    return words.trim();
  };

  // Remove all non-digit characters except the decimal point
  amount = amount.replace(/[^0-9.]/g, "");

  // Split into dollars and cents
  let [dollars, cents] = amount.split(".");

  // Convert dollars part to words
  let dollarsInWords = numberToWords(dollars || "0");

  // Convert cents part to words, or default to zero
  let centsInWords = cents ? numberToWords(cents) : "zero";

  // Combine and format result
  let result = `${dollarsInWords} dollars and ${centsInWords} cents`;

  // Convert to lowercase and capitalize the first letter
  return result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();
}

export function currencyFormatter(num) {
  // Check if the input is null, undefined, or a boolean
  try {
    // Try to parse the input as a number
    const parsedNum = parseFloat(num);

    // Check if the parsed number is valid
    if (isNaN(parsedNum)) {
      return false;
    }

    // Format the number to have commas for thousands and up to 2 decimal places
    const formatted = parsedNum.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    // Remove '.00' if present, otherwise return the formatted string
    return formatted;
  } catch (e) {
    console.error(e.message);
    return false;
  }
}
