import React, { useState, useEffect } from "react";
import {
  Modal,
  Table,
  Form,
  Row,
  Col,
  Button,
  Input,
  Tooltip,
} from "antd";
import { SendOutlined } from "@ant-design/icons"; // Import the paper airplane icon

import { formatDate } from "../../../utils/functions/dates";

const SendECheckModal = ({
  setECheckModalOpen,
  eCheckModalOpen,
  handleSendEmail,
  selectedChecksToPrint,
}) => {
  const [form] = Form.useForm();
  const [emailInputs, setEmailInputs] = useState({}); // To store email input values per row

  // Handle updating email input for each row dynamically
  const handleEmailInputChange = (checkId, email) => {
    setEmailInputs((prevInputs) => ({
      ...prevInputs,
      [checkId]: email, // Update only the specific row's email input
    }));
  };

  const columns = [
    {
      title: "Email Check To",
      key: "emailCheckTo",
      render: (text, record) => {
        const isEmailSent = emailInputs[record.id] === "sent";
        return isEmailSent ? (
          <span>Email Sent</span>
        ) : (
          <Input
            value={emailInputs[record.id] || record.payee?.email || ""} // Use email input or fallback to payee email
            placeholder="Enter email"
            style={{ width: 300 }}
            onChange={
              (e) => handleEmailInputChange(record.id, e.target.value) // Update email value in state
            }
            suffix={
              <Tooltip title="Send Email">
                <Button
                  className="button-yellow"
                  icon={<SendOutlined />}
                  onClick={() =>
                    handleSendEmail(
                      record,
                      emailInputs[record.id] || record.payee?.email // Send email from current input value
                    )
                  }
                  style={{ padding: "0 8px" }} // Adjust padding for a compact button
                />
              </Tooltip>
            }
          />
        );
      },
    },
    { title: "Check Number", dataIndex: "checkNumber", key: "checkNumber" },
    {
      title: "Account",
      key: "accountNickname",
      render: (text, record) => record.account?.accountNickname || "N/A", // Safely access nested property
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => `$${parseFloat(amount).toFixed(2)}`,
    },
    { title: "Payable To", dataIndex: "payableTo", key: "payableTo" },
    {
      title: "Check Memo",
      dataIndex: "memo",
      key: "memo",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => formatDate(date),
    },
  ];

  return (
    <>
      <Modal
        title="Email Checks"
        open={eCheckModalOpen}
        footer={false}
        style={{
          minWidth: "90%",
          marginTop: -70,
        }}
        styles={{
          maxHeight: "60vh", // Controls the content's scrollable area
          overflowY: "auto", // Ensures content inside the modal scrolls
        }}
        maskClosable={false}
        onCancel={() => {
          setECheckModalOpen(false);
        }}
      >
        <div style={{ overflowX: "auto", overflowY: "auto" }}>
          <Table
            rowKey="id"
            columns={columns}
            dataSource={selectedChecksToPrint}
            pagination={false}
          />
        </div>
        <Row style={{ marginTop: 15 }}>
          <Col span={20}></Col>
          <Col span={4} style={{ textAlign: "right" }}>
            <Button type="primary" onClick={() => setECheckModalOpen(false)}>
              Close
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default SendECheckModal;
