import React, { useState, useEffect } from "react";
import { functions, db } from "../../../firebase";
import { httpsCallable } from "firebase/functions";
import { useAuth } from "../../../contexts/AuthContext";
import {
  doc,
  updateDoc,
  collection,
  getDocs,
  setDoc,
  query,
  where,
} from "firebase/firestore";
import {
  Table,
  Button,
  Row,
  Col,
  Modal,
  Spin,
  message,
  Tag,
  Menu,
  Dropdown,
  Popconfirm,
} from "antd";
import BankingVerification from "../../Onboarding/NewUserJourney/BankingVerification";
import { DisplaySignature } from "../../../shared/Components/Signature";
import { DownOutlined } from "@ant-design/icons";
import { SignaturePad } from "../../../shared/Components/Signature";

function BankAccountsTab() {
  const [values, setValues] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState([]);
  const { user, setUser } = useAuth();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disableAdd, setDisableAdd] = useState(false);
  const [signatureModal, setSignatureModal] = useState(false);
  const STATUS = {
    DISABLED: "disabled",
    VERIFIED: "verified",
    PENDING_VERIFICATION: "pending verification",
    PRE_APPROVED: "pre-approved",
  };
  const VERIFICATION_TYPE = {
    PLAID: "plaid",
    UPLOAD: "upload",
    MICRODEPOSITS: "microdeposits",
  };

  useEffect(() => {
    if (user && !open) {
      fetchAccounts();
    }
  }, [user, open]);

  const fetchAccounts = async () => {
    setLoading(true);
    try {
      const accountsRef = collection(db, `clients/${user.clientId}/accounts`);
      const queryAccounts = query(accountsRef, where("isDeleted", "==", false));
      const snapshot = await getDocs(queryAccounts);
      const encryptedData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      if (encryptedData && encryptedData.length === 0) {
        const clientRef = doc(db, "clients", user.clientId); // Reference to the client document
        await setDoc(
          clientRef,
          {
            verifications: {
              bank: false,
            },
          },
          { merge: true } // Use merge to update only verifications.sms
        );

        if (user?.clientData?.verifications?.bank !== false) {
          setUser({
            ...user,
            clientData: {
              ...user.clientData,
              verifications: {
                ...user.clientData.verifications,
                bank: false,
              },
            },
          });
        }
      }

      const decryptedValues = await Promise.all(
        encryptedData.map(async (account) => {
          const decryptedAccountInfo = await handleDecrypt(
            account.encryptedAccount
          );
          // Destructure the account object to remove encryptedAccount
          const { encryptedAccount, ...restOfAccount } = account;
          return {
            ...restOfAccount, // Include all properties except encryptedAccount
            account_info: decryptedAccountInfo,
          };
        })
      );

      setValues(decryptedValues);

      if (decryptedValues.length > 1 && !user.isPremium && !user.isEnterprise) {
        setDisableAdd(true);
      } else {
        setDisableAdd(false);
      }
    } catch (error) {
      message.error("Error fetching accounts:");
      console.error("Error fetching accounts:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAdd = () => {
    if (disableAdd) {
      message.warning("You must upgrade to Premium to add multiple accounts");
    }
    setOpen(true);
  };

  const returnTag = (account) => {
    if (account.status === STATUS.VERIFIED && !account.isDisabled)
      return <Tag color="success">Verified</Tag>;
    if (account.status === STATUS.PENDING_VERIFICATION)
      return <Tag color="warning">Pending Verification</Tag>;
    if (account.status === STATUS.DISABLED || account.isDisabled)
      return <Tag color="error">Disabled</Tag>;
    if (account.status === STATUS.PRE_APPROVED || account.isDisabled)
      return <Tag color="success">Pre-Approved</Tag>;
    return <Tag color="default">{account.status}</Tag>;
  };

  const handleDecrypt = async (encryptedValue) => {
    const decryptFunction = httpsCallable(functions, "decryptData");
    const decrypted = await decryptFunction({ encryptedText: encryptedValue });
    return JSON.parse(decrypted.data.plainText);
  };

  const toggleDisable = async (accountId, isDisabled) => {
    const accountRef = doc(db, `clients/${user.clientId}/accounts`, accountId);
    await updateDoc(accountRef, {
      isDisabled: !isDisabled,
    });
    fetchAccounts(); // Refresh data
  };

  const deleteAccount = async (accountId) => {
    const accountRef = doc(db, `clients/${user.clientId}/accounts`, accountId);
    await updateDoc(accountRef, { isDeleted: true });
    fetchAccounts(); // Refresh data
  };
  // console.log(values);

  const columns = [
    {
      title: "Actions",
      key: "actions",
      render: (text, account) => {
        const menu = (
          <Menu>
            <Menu.Item
              key="1"
              onClick={() => toggleDisable(account.id, account.isDisabled)}
            >
              {account?.isDisabled ? "Enable" : "Disable"}
            </Menu.Item>
            {/* {account?.account_info?.signature ? (
              <Menu.Item
                key="2"
                onClick={() => {
                  setSelectedAccount([account]);
                  setSignatureModal(true);
                }}
              >
                Delete Signature
              </Menu.Item>
            ) : (
              <Menu.Item
                key="2"
                onClick={() => {
                  setSelectedAccount([account]);
                  setSignatureModal(true);
                }}
              >
                Add Signature
              </Menu.Item>
            )} */}
            <Menu.Item key="3">
              <Popconfirm
                title="Are you sure you want to delete this account?"
                onConfirm={() => deleteAccount(account.id)} // Confirm triggers the deletion
                okText="Yes"
                cancelText="No"
              >
                <span className="error">Delete Account</span>
              </Popconfirm>
            </Menu.Item>
          </Menu>
        );

        return (
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button>
              Actions <DownOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
    {
      title: "Account Nickname",
      dataIndex: ["account_info", "accountNickname"],
      key: "accountNickname",
    },
    {
      title: "Status",
      key: "status",
      render: (text, account) => <>{returnTag(account)}</>,
    },
    {
      title: "Bank",
      dataIndex: ["account_info", "institution_name"],
      key: "institution_name",
    },
    // {
    //   title: "Account Name",
    //   dataIndex: ["account_info", "name"],
    //   key: "name",
    // },
    {
      title: "Routing Number",
      dataIndex: ["account_info", "routing_number"],
      key: "routing_number",
    },
    {
      title: "Account Number",
      dataIndex: ["account_info", "mask"],
      key: "account_number",
    },
    {
      title: "Account Owner",
      dataIndex: ["account_info", "ownerName"],
      key: "ownerName",
    },
    {
      title: "Address 1",
      dataIndex: ["account_info", "addressLine1"],
      key: "address1",
    },
    {
      title: "Address 2",
      dataIndex: ["account_info", "addressLine2"],
      key: "address2",
    },
    // {
    //   title: "Print Signature",
    //   render: (text, account) => (
    //     <>{account.account_info.signature === true ? "True" : "False"}</>
    //   ),
    //   key: "signature",
    // },
    {
      title: "Signature",
      render: (text, account) => (
        <DisplaySignature
          encodedSignature={account.account_info.signatureImg}
          height={30}
        />
      ),
      key: "signature",
    },

    // render: (text, account) => (
    //   <Row gutter={16}>
    //     <Button onClick={() => toggleDisable(account.id, account.isDisabled)}>
    //       {account.isDisabled ? "Enable" : "Disable"}
    //     </Button>

    //     <Button danger onClick={() => deleteAccount(account.id)}>
    //       Delete
    //     </Button>
    //   </Row>
    // ),
  ];
  // console.log(values);
  return (
    <>
      <Row>
        <Button type="primary" style={{ marginBottom: 20 }} onClick={handleAdd}>
          Add Bank Account
        </Button>
      </Row>
      <Row>
        <Col span={24} style={{ textAlign: "center", overflow: "auto" }}>
          {loading ? (
            <Spin size="large" style={{ marginTop: 50, marginBottom: 50 }} />
          ) : (
            <Table
              dataSource={values}
              columns={columns}
              rowKey="id"
              style={{ marginBottom: 20 }}
            />
          )}
        </Col>
      </Row>
      <Modal
        title="Add Bank Account"
        width="800"
        style={{ marginTop: -80 }}
        maskClosable={false}
        footer={null}
        open={open}
        onCancel={() => setOpen(false)} // This should be enough to close the modal
      >
        <BankingVerification  onCancel={() => setOpen(false)} />
      </Modal>
      <Modal
        open={signatureModal}
        onClose={() => setSignatureModal(false)}
        footer={null}
        maskClosable={false}
        closeIcon={null}
      >
        <SignaturePad
          isOpen={signatureModal}
          selectedAccount={selectedAccount}
          setSelectedAccount={setSelectedAccount}
          onClose={() => setSignatureModal(false)}
          renderPad={signatureModal}
        />
      </Modal>
    </>
  );
}

export default BankAccountsTab;
