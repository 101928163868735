import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Card,
  Typography,
  notification,
  Alert,
} from "antd";
import { useAuth } from "../../../contexts/AuthContext";
import {
  MailOutlined,
  MobileOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { sendEmailVerification } from "firebase/auth";
import { Link } from "react-router-dom";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function EmailSmsVerification({ previousStep, nextStep }) {
  const { user, firebaseUserData, reloadUser, setUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [emailNotificationSent, setEmailNotificationSent] = useState(true);
  const [smsVerified, setSmsVerified] = useState(false);
  const [smsNotificationSent, setSmsNotificationSent] = useState(false);
  const [smsCodeInput, setSmsCodeInput] = useState(null);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: user.email,
    phone: user.phone,
  });
  const [form] = Form.useForm(); // Form instance
  const url = process.env.REACT_APP_API_ENDPOINT;

  useEffect(() => {
    async function run() {
      await verifyEmailCode(false);
      setEmailVerified(user.emailVerified);
    }
    run();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  function sendEmailCode() {
    setLoading(true);
    sendEmailVerification(firebaseUserData)
      .then(() => {
        setEmailNotificationSent(true);
        notification.success({
          message: "Verification email sent successfully",
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notification.error({
          message:
            "Error sending verification email. Please wait 1 minute before requesting another email link",
        });
        console.error("Error sending verification email:", error);
      });
  }

  const verifyEmailCode = async (notify = true) => {
    setLoading(true);
    await reloadUser();
    if (!user.emailVerified) {
      if (notify) {
        notification.warning({
          message:
            "Email not verified. Please click on the link in the email sent to you",
        });
      }
      setLoading(false);
      return;
    }
    try {
      const clientRef = doc(db, "clients", user.clientId);

      // Update only the email field in client.verifications
      await setDoc(
        clientRef,
        {
          verifications: {
            email: true,
          },
        },
        { merge: true } // This ensures that only the verifications field is updated and not overwritten
      );
      setEmailVerified(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (notify) {
        notification.error({ message: "Error saving email verification" });
      }
      console.error("Error updating email verification:", error.message);
    }
  };

  function sendSmsCode() {
    form
      .validateFields()
      .then((values) => {
        setLoading(true); // Start loading

        const { phone } = values;

        // Make the Axios POST request
        axios
          .post(
            `${url}/v1/sms/send-verification`,
            {
              phoneNumber: phone,
            },
            {
              headers: {
                Authorization: `Bearer ${user.accessToken}`,
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              setSmsNotificationSent(true);
            }
          })
          .catch((error) => {
            console.error("Error sending SMS verification:", error);
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((errorInfo) => {
        console.log("Validation Failed:", errorInfo);
      });
  }

  async function verifySmsCode() {
    form
      .validateFields()
      .then(async (values) => {
        setLoading(true); // Start loading

        const { smsCode, phone } = formData;

        try {
          // Make the Axios POST request
          const response = await axios.post(
            `${url}/v1/sms/verify-code`,
            {
              phoneNumber: phone, // The phone number from the form data
              code: smsCode, // The SMS code from formData
            },
            {
              headers: {
                Authorization: `Bearer ${user.accessToken}`, // Authorization header with the user's access token
              },
            }
          );

          // Check for status 200 and body response success
          if (response.status === 200 && response.data.success === true) {
            setSmsVerified(true); // Mark the SMS as verified
            notification.success({ message: "SMS Verified Successfully" });

            // 1. Update user phone in Firebase if it's different
            if (phone !== user.phone) {
              const userRef = doc(db, "users", user.uid); // Reference to the user document
              await setDoc(
                userRef,
                { phone }, // Only update phone
                { merge: true } // Use merge to prevent overwriting the document
              );
            }

            // 2. Update the client's verifications.sms to true in Firebase
            const clientRef = doc(db, "clients", user.clientId); // Reference to the client document
            await setDoc(
              clientRef,
              {
                verifications: {
                  sms: true,
                },
              },
              { merge: true } // Use merge to update only verifications.sms
            );
          } else {
            notification.error({
              message: "Verification failed",
              description: "Invalid code or response",
            });
          }
        } catch (error) {
          console.error(
            "Error verifying SMS code or updating Firebase:",
            error
          );
          notification.error({
            message: "Failed to verify SMS code",
            description: error.message || "Unknown error occurred",
          });
        } finally {
          setLoading(false); // Stop loading regardless of success/failure
        }
      })
      .catch((errorInfo) => {
        console.log("Validation Failed:", errorInfo);
      });
  }

  function handleComplete() {
    setUser({
      ...user,
      clientData: {
        ...user.clientData,
        verifications: {
          ...user.clientData.verifications,
          sms: true,
          email: true,
        },
      },
    });
    navigate(-1);
  }

  return (
    <>
      <Row gutter={16}>
        <Col xs={12}>
          <Card style={{ height: "350px" }}>
            <Row>
              <Col xs={24} style={{ textAlign: "center", marginBottom: 24 }}>
                <MailOutlined className="info" style={{ fontSize: "2rem" }} />
                <Typography.Paragraph style={{ marginTop: 10 }}>
                  Email Verification
                </Typography.Paragraph>
              </Col>
            </Row>
            {emailVerified ? (
              <Row>
                <Col xs={24} style={{ textAlign: "center", marginBottom: 24 }}>
                  <CheckCircleOutlined
                    className="success"
                    style={{ fontSize: "3rem" }}
                  />
                </Col>
              </Row>
            ) : (
              <Row>
                <Form
                  form={form} // Pass the form instance here
                  initialValues={formData}
                  onValuesChange={(changedValues) =>
                    handleChange({
                      target: {
                        name: Object.keys(changedValues)[0],
                        value: Object.values(changedValues)[0],
                      },
                    })
                  }
                >
                  <Row gutter={16}>
                    <Col xs={24}>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "please enter your email",
                          },
                        ]}
                        disabled
                      >
                        <Input placeholder="Email" disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      {emailNotificationSent ? (
                        <>
                          <Button
                            onClick={verifyEmailCode}
                            block
                            type="primary"
                            disabled={loading}
                          >
                            Verify
                          </Button>
                          <Link type="link" onClick={sendEmailCode}>
                            Resend email
                          </Link>

                          <Alert
                            type="warning"
                            showIcon
                            message="Please check spam/junk inbox."
                            style={{ textAlign: "center", marginTop: 15 }}
                          />
                        </>
                      ) : (
                        <Button
                          onClick={sendEmailCode}
                          block
                          type="primary"
                          disabled={loading}
                        >
                          Send Verification Email
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Form>
              </Row>
            )}
          </Card>
        </Col>
        <Col xs={12}>
          <Card style={{ height: "350px" }}>
            <Row>
              <Col xs={24} style={{ textAlign: "center", marginBottom: 24 }}>
                <MobileOutlined className="info" style={{ fontSize: "2rem" }} />
                <Typography.Paragraph style={{ marginTop: 10 }}>
                  SMS Verification{" "}
                </Typography.Paragraph>
              </Col>
            </Row>
            {smsVerified ? (
              <Row>
                <Col xs={24} style={{ textAlign: "center", marginBottom: 24 }}>
                  <CheckCircleOutlined
                    className="success"
                    style={{ fontSize: "3rem", marginTop: 35 }}
                  />
                </Col>
              </Row>
            ) : (
              <Row>
                <Form
                  form={form} // Pass the form instance here
                  initialValues={formData}
                  onValuesChange={(changedValues) =>
                    handleChange({
                      target: {
                        name: Object.keys(changedValues)[0],
                        value: Object.values(changedValues)[0],
                      },
                    })
                  }
                >
                  <Row gutter={16}>
                    <Col xs={24}>
                      {smsNotificationSent ? (
                        <>
                          {" "}
                          <Form.Item
                            name="smsCode"
                            rules={[
                              {
                                required: true,
                              },
                              {
                                min: 4,
                                message:
                                  "phone number must be at least 10 characters",
                              },
                            ]}
                            disabled
                          >
                            <Input placeholder="Verification Code" />
                          </Form.Item>
                        </>
                      ) : (
                        <>
                          <Form.Item
                            name="phone"
                            rules={[
                              {
                                required: true,
                              },
                              {
                                min: 10,
                                message:
                                  "phone number must be at least 10 characters",
                              },
                            ]}
                            disabled
                          >
                            <Input placeholder="Mobile Number" />
                          </Form.Item>
                        </>
                      )}
                    </Col>
                    <Col xs={24}>
                      {smsNotificationSent ? (
                        <>
                          <Button
                            onClick={verifySmsCode}
                            block
                            type="primary"
                            disabled={loading}
                          >
                            Verify
                          </Button>
                          <Link type="link" onClick={sendSmsCode}>
                            Resend SMS
                          </Link>
                        </>
                      ) : (
                        <Button
                          onClick={sendSmsCode}
                          block
                          type="primary"
                          disabled={loading}
                        >
                          Send SMS Code
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Form>
              </Row>
            )}
          </Card>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} style={{ textAlign: "right", marginTop: 50 }}>
          {nextStep ? (
            <Button
              type="primary"
              onClick={nextStep}
              disabled={smsVerified && emailVerified ? false : true}
            >
              Continue
            </Button>
          ) : (
            <Button
              type="primary"
              disabled={smsVerified && emailVerified ? false : true}
              onClick={handleComplete}
            >
              Complete
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
}

export default EmailSmsVerification;
