import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Card,
  notification,
  Form,
  Row,
  Col,
  Input,
  Alert,
  Typography,
  Popconfirm,
  Tooltip,
} from "antd";
import {
  PlusOutlined,
  PrinterOutlined,
  FileExcelOutlined,
  EditOutlined,
  RedEnvelopeOutlined,
  MailOutlined,
  ExportOutlined,
  ExclamationCircleOutlined,
  MobileOutlined,
  BankOutlined,
} from "@ant-design/icons";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  getDoc,
  query,
  where,
  orderBy,
  limit,
} from "firebase/firestore";
import { StyleSheet } from "@react-pdf/renderer";
import { db, functions } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import CheckForm from "./Components/CheckForm";
import CheckTable from "./Components/CheckTable";
import PrintChecksModal from "./Components/PrintChecksModal";
import moment from "moment";
import * as XLSX from "xlsx";
import { checkMetaUpsert } from "../../services/metadata/checks";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Tutorial from "./Components/Tutorial";
import axios from "axios";
import SendECheckModal from "./eChecks/SendECheckModal";

const ChecksPage = () => {
  const DEBUG_MODE = process.env.REACT_APP_ENVIRONMENT != "prod" ? true : false;
  const [checks, setChecks] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [filteredChecks, setFilteredChecks] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingCheck, setEditingCheck] = useState(null);
  const [form] = Form.useForm();
  const [payeeSearchResults, setPayeeSearchResults] = useState([]);
  const [isPrintModalVisible, setIsPrintModalVisible] = useState(false);
  const [selectedChecksToPrint, setSelectedChecksToPrint] = useState([]);
  const [isPDFModalVisible, setIsPDFModalVisible] = useState(false);
  const [template, setTemplate] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const [accounts, setAccounts] = useState(null);
  const [lastCheckNumber, setLastCheckNumber] = useState(null);
  const [eCheckModalOpen, setECheckModalOpen] = useState(false);
  const { user, setUser } = useAuth();
  const navigate = useNavigate();

  const fetchChecks = async () => {
    try {
      const checksCollection = query(
        collection(db, `clients/${user.clientId}/checks`),
        where("isDeleted", "==", false),
        orderBy("dateAdded", "desc")
      );
      const checksSnapshot = await getDocs(checksCollection);
      const checksList = checksSnapshot?.docs?.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Set all checks
      setChecks(checksList);
      setFilteredChecks(checksList);
    } catch (e) {
      console.error(e.message);
      notification.error({
        message: "Error fetching checks",
      });
    }
  };

  const filterChecksToPrint = (checksList, draft = false) => {
    let checksToPrint = [];
    if (draft) {
      checksToPrint = checksList.filter(
        (check) => check.toBePrinted || check.status.toLowerCase() === "draft"
      );
    } else {
      checksToPrint = checksList.filter((check) => check.toBePrinted);
    }
    setSelectedChecksToPrint(checksToPrint);
  };

  const openECheckModal = (check = false) => {
    filterChecksToPrint(checks, true);
    if (check && check.id) {
      filterChecksToPrintById(checks, check.id, true);
    }

    setECheckModalOpen(true);
  };

  const filterChecksToPrintById = (checksList, checkId, draft = false) => {
    let checksToPrint = [];
    if (draft) {
      checksToPrint = checksList.filter(
        (check) =>
          (check.toBePrinted || check.status.toLowerCase() === "draft") &&
          check.id === checkId
      );
    } else {
      checksToPrint = checksList.filter(
        (check) => check.toBePrinted && check.id === checkId
      );
    }

    setSelectedChecksToPrint(checksToPrint);
  };

  const fetchAccounts = async () => {
    const ACCOUNT_STORAGE_KEY = "encryptedAccountData";
    // const storedAccounts = sessionStorage.getItem(ACCOUNT_STORAGE_KEY);
    const storedAccounts = false;

    try {
      if (storedAccounts && storedAccounts.length > 0) {
        setAccounts(await JSON.parse(storedAccounts));
        // console.log('rehydrated accounts', accounts)
      } else {
        const accountsRef = collection(db, `clients/${user.clientId}/accounts`);
        const queryAccounts = query(
          accountsRef,
          where("isDeleted", "==", false)
        );

        const snapshot = await getDocs(queryAccounts);
        const encryptedData = snapshot?.docs?.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // console.log(encryptedData);
        sessionStorage.setItem(
          ACCOUNT_STORAGE_KEY,
          await JSON.stringify(encryptedData)
        );
        setAccounts(encryptedData);
        // console.log('account pulling data', accounts)
      }
    } catch (error) {
      notification.error("Error fetching accounts:");
      console.error("Error fetching accounts:", error);
    }
  };

  const fetchTemplate = async () => {
    const TEMPLATE_STORAGE_KEY = "templateData";
    try {
      // Check if template is already stored in local storage
      const storedTemplate = sessionStorage.getItem(TEMPLATE_STORAGE_KEY);

      if (storedTemplate) {
        // If template is found in local storage, parse it and set it in state
        setTemplate(JSON.parse(storedTemplate));
      } else {
        // If not found in local storage, fetch it from Firebase
        const templatesCollection = collection(
          db,
          `clients/${user.clientId}/templates`
        );
        const templateSnapshot = await getDocs(templatesCollection);

        if (!templateSnapshot.empty) {
          const allTemplateData = templateSnapshot?.docs?.map((doc) =>
            doc.data()
          );

          // Store the fetched templates in local storage
          sessionStorage.setItem(
            TEMPLATE_STORAGE_KEY,
            JSON.stringify(allTemplateData)
          );

          // Set the fetched templates in state
          setTemplate(allTemplateData);
        }
      }
    } catch (e) {
      console.error(e.message);
      notification.error({
        message: "You don't have permission to fetch templates",
      });
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);
    const filteredData = checks.filter((check) =>
      Object.keys(check).some((key) =>
        String(check[key]).toLowerCase().includes(value)
      )
    );
    setFilteredChecks(filteredData);
  };

  useEffect(() => {
    if (user) {
      fetchChecks();
      fetchTemplate();
      fetchAccounts();
    }
  }, [user]);

  const fetchLastPayer = async () => {
    const lastCheckQuery = query(
      collection(db, `clients/${user.clientId}/checks`),
      orderBy("date", "desc"),
      limit(1)
    );
    const lastCheckSnapshot = await getDocs(lastCheckQuery);
    if (!lastCheckSnapshot.empty) {
      return lastCheckSnapshot.docs[0].data().payer;
    }
    return null;
  };

  const exportToCsv = () => {
    const dataToExport = filteredChecks?.map((check) => ({
      "Check Number": check.checkNumber,
      Amount: `$${parseFloat(check.amount).toFixed(2)}`,
      Status: check.status,
      Payee: check.payee.name,
      "Payable To": check.payableTo,
      Date: check.date
        ? moment(check?.date?.toDate()).format("YYYY-MM-DD")
        : "",
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const csvData = XLSX.utils.sheet_to_csv(worksheet);

    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute("download", "checks.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const fetchPayers = async () => {
    const payersCollection = collection(db, `clients/${user.clientId}/payers`);
    const payersSnapshot = await getDocs(payersCollection);
    return payersSnapshot?.docs?.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  };

  const showModal = async (check = null) => {
    const payers = await fetchPayers();
    // const templates = await fetchTemplates();
    const lastPayer = await fetchLastPayer();
    // console.log(templates);
    setEditingCheck(check);
    setIsEditMode(!!check);
    form.setFieldsValue(
      check
        ? {
            ...check,
            date: check.date ? moment(check?.date?.toDate()) : "",
            toBePrinted: false,
          }
        : { payer: lastPayer ? lastPayer : "", payers, template }
    );
    setIsModalVisible(true);
  };

  const handleOk = async (formattedValues) => {
    delete formattedValues.email;
    delete formattedValues.phoneNumber;
    let docRef;
    try {
      const now = new Date();

      if (isEditMode && editingCheck) {
        docRef = await updateDoc(
          doc(db, `clients/${user.clientId}/checks`, editingCheck?.id),
          {
            ...formattedValues,
            dateModified: now,
          }
        );
        checkMetaUpsert(user, {
          ...formattedValues,
          id: docRef?.id || editingCheck?.id, // Use the ID from docRef for new documents
        });
      } else {
        // console.log(formattedValues);
        docRef = await addDoc(
          collection(db, `clients/${user.clientId}/checks`),
          {
            ...formattedValues,
            dateAdded: now,
            dateModified: now,
            isDeleted: false,
            // clientId: user.clientId,
          }
        );
        checkMetaUpsert(user, {
          ...formattedValues,
          id: docRef?.id || editingCheck?.id, // Use the ID from docRef for new documents
        });
      }

      fetchChecks();
    } catch (e) {
      console.error(e.message);
      notification.error({
        message: "You don't have permission to edit this record",
      });
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDelete = async (id) => {
    const now = new Date();
    const values = {
      isDeleted: true,
      dateModified: now,
      lastEditedBy: { displayName: user.displayName, uid: user.uid },
    };
    try {
      await updateDoc(doc(db, `clients/${user.clientId}/checks`, id), values);
      checkMetaUpsert(user, values);
      fetchChecks();
    } catch (e) {
      console.error(e.message);
      notification.error({
        message: "Error deleting check",
      });
    }
  };

  const handleVoid = async (id) => {
    const now = new Date();
    const values = {
      status: "void",
      dateModified: now,
      lastEditedBy: { displayName: user.displayName, uid: user.uid },
    };
    try {
      await updateDoc(doc(db, `clients/${user.clientId}/checks`, id), values);
      checkMetaUpsert(user, values);
      fetchChecks();
    } catch (e) {
      console.error(e.message);
      notification.error({
        message: "Error voiding check",
      });
    }
  };

  // const handlePayeeSearch = async (value) => {
  //   if (value.length < 3) return;

  //   const payeesQuery = query(
  //     collection(db, `clients/${user.clientId}/payees`),
  //     where("name", ">=", value),
  //     where("name", "<=", value + "\uf8ff")
  //   );
  //   const payeesSnapshot = await getDocs(payeesQuery);
  //   const payeesList = payeesSnapshot.docs.map((doc) => ({
  //     id: doc.id,
  //     ...doc.data(),
  //   }));
  //   setPayeeSearchResults(payeesList);
  // };

  const handlePayeeSelect = async (payeeId) => {
    const payeeDoc = await getDoc(
      doc(db, `clients/${user.clientId}/payees`, payeeId)
    );
    const payeeData = payeeDoc.data();
    form.setFieldsValue({
      payee: payeeId,
      payeeName: payeeData.name,
      payeeAddress: payeeData.address,
    });

    const lastCheckQuery = query(
      collection(db, `clients/${user.clientId}/checks`),
      where("payee.id", "==", payeeId),
      orderBy("date", "desc"),
      limit(1)
    );
    const lastCheckSnapshot = await getDocs(lastCheckQuery);
    if (!lastCheckSnapshot.empty) {
      const lastCheckData = lastCheckSnapshot.docs[0].data();
      form.setFieldsValue(lastCheckData);
    }
  };

  const openPrintModal = () => {
    filterChecksToPrint(checks);
    setIsPrintModalVisible(true);
  };

  const handlePrint = () => {
    setIsPrintModalVisible(false);
    setIsPDFModalVisible(true);
  };

  const handlePrintConfirm = async (printedCorrectly) => {
    if (printedCorrectly) {
      let currentCheckNumber = lastCheckNumber; // Use a local variable to keep track of the check number

      for (let i = 0; i < selectedChecksToPrint.length; i++) {
        const check = selectedChecksToPrint[i];

        // Update check
        await updateDoc(doc(db, `clients/${user.clientId}/checks`, check.id), {
          checkNumber: currentCheckNumber,
          status: "printed",
          printDate: Date.now(),
          toBePrinted: false,
        });

        // Update meta checks
        await updateDoc(doc(db, `metaChecks`, check.id), {
          checkNumber: currentCheckNumber,
          status: "printed",
          printDate: Date.now(),
          toBePrinted: false,
        });

        // Increment the local variable for the next check
        currentCheckNumber++;
      }

      // After the loop, update the state with the last used check number
      setLastCheckNumber(currentCheckNumber);

      notification.success({
        message: "Checks printed and updated successfully",
      });

      fetchChecks();
      setIsPDFModalVisible(false);
    }

    setIsPDFModalVisible(false);
  };

  const handleSendEmail = async (check, email) => {
    if (DEBUG_MODE) console.log("here", email, check, user);
  
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const account = accounts.find((acc) => acc.id === check.account.id);
    const url = process.env.REACT_APP_API_ENDPOINT;
  
    if (DEBUG_MODE) {
      console.log("checks to print", selectedChecksToPrint);
      console.log("accounts", accounts);
      console.log("account found", account);
      console.log(lastCheckNumber);
    }
  
    // Check if email is null, empty, or doesn't match the pattern
    if (!email || !emailPattern.test(email)) {
      console.error("Invalid email address: ", email);
      notification.error({
        message: "Invalid Email",
        description: "Please enter a valid email address: " + email,
      });
      return;
    }
  
    // Use a local variable to track the incremented check number
    let currentCheckNumber = lastCheckNumber;
  
    try {
      const response = await axios.post(
        `${url}/v1/checks/${check.id}/send-email`, // Set the correct URL
        {
          recipientEmail: email,
          recipientName: check.payableTo,
          checkNumber: currentCheckNumber,  // Use local variable
          senderAccountOwnerName: account.ownerName,
        }, // Send email as body payload
        {
          headers: {
            Authorization: `Bearer ${user.accessToken}`, // Authorization header
          },
        }
      );
  
      if (response.status === 200) {
        // Increment the local variable for the next check
        currentCheckNumber++;
  
        // After the email is successfully sent, update the state with the latest check number
        setLastCheckNumber(currentCheckNumber);
      }
    } catch (error) {
      console.error("Error sending email:", error);
      // Handle error if necessary (e.g., display message)
    }
  };
  

  return (
    <>
      <Tutorial />

      <h1 className="monospace">
        <EditOutlined /> Checks
      </h1>
      <div className="alert-div">
        {user && !user?.clientData?.verifications?.sms && (
          <Alert
            style={{ marginTop: 10, marginBottom: 10 }}
            type="warning"
            message={
              <Typography.Text>
                <MobileOutlined className="warning" /> &emsp; Complete your
                profile{" "}
                <Link to="/onboarding/sms">
                  <strong>validate your phone number</strong>
                </Link>
              </Typography.Text>
            }
          ></Alert>
        )}
        {user && !user?.clientData?.verifications?.bank && (
          <Alert
            style={{ marginTop: 10, marginBottom: 10 }}
            type="warning"
            message={
              <Typography.Text>
                <BankOutlined className="warning" /> &emsp; To create checks you
                please{" "}
                <Link to="/settings/bank-accounts">
                  <strong>validate bank account</strong>
                </Link>
              </Typography.Text>
            }
          ></Alert>
        )}
      </div>
      <Card>
        <Row gutter={16} style={{ marginBottom: 16 }}>
          <Col span={12}>
            {user?.clientData?.verifications?.bank ? (
              <Button
                className="new-check-btn button-blue"
                icon={<PlusOutlined />}
                onClick={() => {
                  form.resetFields();
                  setEditingCheck(false);
                  showModal(null);
                }}
                style={{ marginRight: 8 }}
              >
                New Check
              </Button>
            ) : (
              <Popconfirm
                title="You don't have any validated bank accounts in order to write checks. It's quick and easy:"
                onConfirm={() => {
                  navigate("/settings/bank-accounts");
                }}
                okText="Validate Bank Account"
                cancelText="Back"
              >
                <Button
                  className="new-check-btn button-blue"
                  icon={<PlusOutlined />}
                  style={{ marginRight: 8 }}
                >
                  New Check
                </Button>
              </Popconfirm>
            )}
            <Button
              icon={<PrinterOutlined />}
              onClick={openPrintModal}
              className="button-green print-checks-btn"
              style={{ marginRight: 8 }}
            >
              Print Checks
            </Button>
            <Tooltip title="Email Check (Coming Soon In November)">
              <Button
                icon={<MailOutlined />}
                className="button-yellow"
                style={{ marginRight: 8 }}
                onClick={openECheckModal}
                disabled={true}
              >
                Email Checks
              </Button>
            </Tooltip>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Input.Search
              placeholder="Search by any field"
              value={searchText}
              onChange={handleSearch}
              style={{ marginRight: 10, width: 300 }}
            />
            <Button icon={<ExportOutlined />} onClick={exportToCsv}>
              Export CSV
            </Button>
          </Col>
        </Row>
        <CheckTable
          checks={filteredChecks}
          showModal={showModal}
          isPDFModalVisible={isPDFModalVisible}
          setIsPdfModalVisible={setIsPDFModalVisible}
          setSelectedChecksToPrint={setSelectedChecksToPrint}
          selectedChecksToPrint={selectedChecksToPrint}
          handleDelete={handleDelete}
          handleVoid={handleVoid}
          handlePrintConfirm={handlePrintConfirm}
          setLastCheckNumber={setLastCheckNumber}
          accounts={accounts}
          templates={template}
          user={user}
          setECheckModalOpen={setECheckModalOpen}
          openECheckModal={openECheckModal}
          eCheckModalOpen={eCheckModalOpen}
          handleSendEmail={handleSendEmail}
          startingCheckNumber={lastCheckNumber}
        />
        <Modal
          title={isEditMode ? "Edit Check" : "New Check"}
          open={isModalVisible}
          maskClosable={false}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
          style={{ minWidth: "80%", marginTop: -35 }}
        >
          <CheckForm
            form={form}
            maskClosable={false}
            isVisible={isModalVisible}
            isEditMode={isEditMode}
            editingCheck={editingCheck}
            onSubmit={handleOk}
            onCancel={handleCancel}
            payers={form.getFieldValue("payers")}
            templates={template}
            accounts={accounts}
            payeeSearchResults={payeeSearchResults}
            // handlePayeeSearch={handlePayeeSearch}
            handlePayeeSelect={handlePayeeSelect}
            user={user}
          />
        </Modal>
        <PrintChecksModal
          isVisible={isPrintModalVisible}
          maskClosable={false}
          accounts={accounts}
          onOk={handlePrint}
          onCancel={() => setIsPrintModalVisible(false)}
          selectedChecksToPrint={selectedChecksToPrint}
          setSelectedChecksToPrint={setSelectedChecksToPrint}
          startingCheckNumber={lastCheckNumber}
          setStartingCheckNumber={setLastCheckNumber}
          templates={template}
          isPDFModalVisible={isPDFModalVisible}
          setIsPDFModalVisible={setIsPDFModalVisible}
          handlePrintConfirm={handlePrintConfirm}
        />
        {eCheckModalOpen && (
          <SendECheckModal
            user={user}
            accounts={accounts}
            setECheckModalOpen={setECheckModalOpen}
            setStartingCheckNumber={setLastCheckNumber}
            eCheckModalOpen={eCheckModalOpen}
            handleSendEmail={handleSendEmail}
            startingCheckNumber={lastCheckNumber}
            selectedChecksToPrint={selectedChecksToPrint}
            setSelectedChecksToPrint={setSelectedChecksToPrint}
          />
        )}
      </Card>
    </>
  );
};

export default ChecksPage;
